import React from 'react'
import { useTheme } from '@material-ui/core'


const BlogHeader = (props) => {

  const theme = useTheme()

  return (
    <h1 style={{ color: theme.palette.primary.main}}>{props.text}</h1>
  )
}

export default BlogHeader