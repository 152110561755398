import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(20,142,101,1)'
    },
    secondary: {
      main: '#102d4c',
    },
  },
  
});

export default theme