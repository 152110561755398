import React from "react"
import Layout from '../components/layout'
import { graphql } from "gatsby"
import { Container, Grid, Box } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../theme/blog-theme'
import './blog-post.css'

import BlogHeader from '../components/blog-header/blog-header'

export default function workTemplate({
  data,
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
  return (
      <Layout>
        <Box mt={8}>
          <Container maxWidth="md" >
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={12} > 
                  <ThemeProvider theme={theme}>
                    <div className="blog-post-container">
                      <div className="blog-post">
                        <BlogHeader text={frontmatter.title} />
                        <span className="author">Posted By: Justin Hurley</span>
                        <span className="separator">|</span>
                        <span className="date">{frontmatter.date}</span>
                        <a href={frontmatter.projectLink} target="_blank">
                          <img 
                            src={frontmatter.image.src} 
                            alt={frontmatter.image.alt}
                            role="presentation"
                            className="blog-post-image"
                          />
                        </a>
                        <div className="projectLink">
                          <h3 style={{textAlign: "center"}}>
                            <a href={frontmatter.projectLink} target="_blank">Project Link</a>
                          </h3>
                        </div>
                        <div
                          className="blog-post-content"
                          dangerouslySetInnerHTML={{ __html: html }}
                        />
                      </div>
                    </div>
                  </ThemeProvider>
                </Grid>
              </Grid>
          </Container>
        </Box>    
      </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        projectLink
        image {
          src
          title
          alt
        }
      }
    }
  }
`